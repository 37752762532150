.portfolio{
    &__item{
        margin: 10px;
        &--image{
            width: 100%;
            transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
        }
    }
}

.tab.active .portfolio__item--image{
    opacity: 1;
}