.portfolio__list {
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  & .swiper-wrapper {
    margin: 0;
  }
  & .swiper-container {
    padding: 0 5rem;
    max-width: 100%;
    margin: auto 0;
    padding: 0;
    height: 230px;
   @include xs{
    height: 233px;
   }
   @include sm{
    height: 256px;
   }
   @include md{
    height: auto
   }
   @include lg{
    height: 50%;
   }
   @include xl{
    height: 50%;
   }
  }
  & .swiper-container:before,
  & .swiper-container:after {
    display: none;
  }
  & .swiper-button-prev, .swiper-button-next{
  top: 50% !important;
  box-shadow: 0 0 1.5rem #00000033;
  
}
  & .swiper-button-prev{
    left: 0!important ;
    border-radius: 0 100% 100% 0!important;
    
}
& .swiper-button-next{
  right: 0!important ;
  border-radius: 100%  0 0 100% !important;
}
& .swiper-slide{
  height: fit-content;
}
& .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
  content: ''!important;
  background-image: url('../../../svg/left_white.svg');
  width: 100%;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  background-size: contain;
  @include xs{
    width: 100%;
  height: 30px;
  } 
}

& .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
  content: ''!important;
  background-image: url('../../../svg/right_white.svg');
  width: 100%;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  background-size: contain;
  @include xs{
    width: 100%;
  height: 30px;
  }
}
}


.portfolio__list.casino{
  & .swiper-button-prev, .swiper-button-next{
    background-color: $color-secondary;
  }
}

.portfolio__list.branding{
  & .swiper-button-prev, .swiper-button-next{
    background-color: $work-light-blue;
  }
}

.portfolio__list.ux{
  & .swiper-button-prev, .swiper-button-next{
    background-color: $work-blue;
  }
}