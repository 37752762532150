.text-component{
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 5;
    @include xs{
        font-size: 9rem;
    }
    & > span{
        display: block;
        color: white;
        height: 8rem;
        &:nth-child(even){
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $color-grey-light-4;
        }
    }
}

