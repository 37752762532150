$color-primary: #49b9d8;
$color-primary-light: #32c9f3;
$color-primary-dark: #2f95b1;

$color-secondary: #dc474d;
$color-secondary-light: #ee373d;
$color-secondary-dark: #af3539;

$color-grey-light-1: #faf9f9;
$color-grey-light-2: #f4f2f2;
$color-grey-light-3: #f0eeee;
$color-grey-light-4: rgb(138, 138, 138);

$color-grey-dark-1: #020202;
$color-grey-dark-2: #333;
$color-grey-dark-3: #444;

$work-blue: #1b6786;
$work-light-blue: #78c0d0;

$shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
$shadow-light: 0 2rem 5rem rgba(0,0,0,.06);