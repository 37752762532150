@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

  * {
    margin: 0;
    padding: 0;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
  
    @include lg {
        font-size: 50%;
    }
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: $color-grey-light-1;
    background-color: $color-grey-dark-1;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

