
.clients{
    height: auto;
    max-width: 100vw;
    padding: 25vh 0;
    height: 75vh;
    @include xs{
        height: 100vh;
        padding: 25vh 0 ;
    }
    @include lg{
        padding: 17vh 0;
    }
    &__carousel{
        margin: 0 auto;
        padding: 0;
    }
}

.clients__carousel svg{
    width: 8rem;
    height: auto;
    margin: 1rem 2rem;
    z-index: 1;
    min-width: 166px;
    @include xs{
        width: 15rem;
    }
}

.swiper-button-prev, .swiper-button-next{
    top: 43% !important;
    border-radius: 50%!important;
    width: 35px!important;
    height: 35px!important;
    @include xs{
        top: 13% !important;
        width: 50px!important;
        height: 50px!important;
    }
}

.swiper-button-prev{
    left: 0;
}

.swiper-button-next{
    right: 0;
}

.swiper-wrapper{
    margin-top: 10rem;
}

.swiper-container:before,
.swiper-container:after {
  content: "";
  position: absolute;
  display: none;
  width: 30rem;
  height: 100%;
  z-index: 3;
  @include xs{
      display: block;
  }
}

.swiper-container{
    padding-left: 10rem;
    padding-right: 10rem;
}

.swiper-container:before {
  top: 0;
  left:-1px;
  background: linear-gradient(to right, #000 0%, transparent 100%);
}

.swiper-container:after {
  bottom: 0;
  right: -2px;
  background: linear-gradient(to left, #000 0%, transparent 100%);
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
    content: ''!important;
    background-image: url('../../../svg/flecha_left.svg');
    width: 90px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
    content: ''!important;
    background-image: url('../../../svg/flecha_right.svg');
    width: 90px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
}

.swiper-slide{
    text-align: center;
}

