.contact{
    text-align: center;
    padding: 25vh 0;
    height: 100vh;
    @include xs{
        padding: 25vh 0;
        height: 100vh;
    }
    @include lg{
        padding: 17vh 0;
    }
    &__icons > a svg{
        width: 9rem;
        height: 9rem;
        margin: 4rem 2rem 0;
        @include xs{
            width: 12rem;
            height: 12rem;
            margin: 4rem 1rem 0;
        }
    }
}