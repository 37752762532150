  .work {
    &__detail {
        padding: 0;
        background-color: inherit;
        border-radius: 1rem;
        margin: 0;
        width: auto;
        min-width: 100%;
        top: 0;
        left: 0;
        position: relative;
        cursor: pointer;
        box-shadow: 0 0 1.5rem #000;
        height: 100%;
        @include md{
            min-width: 35rem;
        }
        &.red {
            background-color: $color-secondary;
        }
        &.light-blue {
            background-color: $work-light-blue;
            z-index: 1;
        }
        &.blue {
            background-color: $work-blue;
            z-index: 1;
        }
      
    }
    &__content {
        &--header{
            display: flex;
            justify-content: space-between;
        }
        & .triangle {
            position: relative;
            width: 3rem;
            margin: 2rem;
            @include md{
                position: absolute;
                top: 4rem;
                left: 3rem;
            }
        }
    }
}

 //Title

.tab .work__detail .work-subtext-component .titulo{
    font-size: 3.3rem;
    transform-origin: 0 0;
    transform: rotate(0);
    position: relative;
    left: 3rem;
    @include md{
        position: absolute;
        bottom: -6rem;
        transform: rotate(-90deg);
    }
}

.tab.active .work__detail .work-subtext-component .titulo{
    transform: rotate(0);
    bottom: 0;
    left: 3rem;
    font-size: 3.6rem;
    @include xs{
        font-size: 6.6rem;
    }
 }

 .tab.unactive .work__detail .work-subtext-component .titulo{
    bottom: 0;
    left: 4rem;
    font-size: 2rem;
    @include md{
        left: 0;
        bottom: -7rem;
    }
    @include lg{
        bottom: -7rem;
    }
 }

 //Triangle

 .tab.active .work__detail .work__content .triangle{
    top: 3rem;
    left: 2rem;
    width: 3rem;
 }

.tab.active .work__detail .work__content .triangle.open{
    opacity: 1;
    width: 4rem;
 }
 
 .tab.active .work__detail .work__content .triangle.close{
    display: none;
    
  }

.tab.unactive .work__detail .work__content .triangle.open{
    display: none;
}

.tab .work__detail .work__content .triangle.close{
    display: block;
    width: 1.3rem;
    top: -2rem;
    left: 1rem;
    @include xs{
        width: 2rem;
        left: 0rem;
        top: -1rem;
    }
    @include md{
        left: 2rem;
        top: 2rem;
    }
 }

 .tab.unactive .work__detail .work__content .triangle.close{
    display: block;
    width: 1.5rem;
    top: -2.5rem;
    left: -1.1rem;
 }


 .tab .work__detail .work__content .triangle.open{
    display: none;
 }

 .tab.active .work__detail .work__content .triangle.open{
    display: block;
    width: 1.5rem;
    transform: rotate(
90deg);
    left: 0rem;
    top: -2rem;
    @include xs{
        transform: rotate(90deg);
        left: -2rem;
        margin: 0;
    top: 0;
    }
    @include md{
        transform: rotate(0);
        left: 2rem;
        top: 2rem;
        width: 2.5rem;
    }
 }

 .tab .work-description{
     opacity: 0;
    display: none;
    transition: opacity 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
   position: absolute;
 }

.tab.active  .work-description{
    display: block;
   opacity:1;
   padding: 3rem;
   top: 40%;
   font-size: 9px;
   @include md{
    top: 67%;
    font-size: 12px;
   }
}