.work-list {
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 8;
      left: 0;
  @include xs {
    display: block;
    left: 0;
  }
  @include md{
   display: flex;
   width: 62%;
   left: 1rem;
  }
  &__item {
    height: 90px;
    width: 100%;
    display: block;
    z-index: 999;
  }
  &__portfolio {
    z-index: 1;
    position: absolute;
    visibility: hidden;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    margin: -1rem auto;
    transform: translate(20rem, 0);
    transition: all 0.5s cubic-bezier(0.075, 0.9, 0.165, 1) 0.3s;
    top: 7rem;
    left: 0rem;
    height: auto;
    @include md{
      width: 460px;
      left: 3rem;
      top: 0;
      height: auto;
      min-height: 423px;  
    }
    @include lg{
      width: 594px;
      left: -10rem;
      height: inherit;
      min-width: 92%;
      min-height: 480px;
    }
    @include xl{
      width: 610px;
      left: -10.5rem;
      height: inherit;
      min-height: 522px;
    }
    &.active {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
      z-index: 1;
      & > image{
        opacity: 1;
      }
    }
    &.unactive{
      opacity: 0;
      transform: translate(8rem, 0);
      & > image{
        opacity: 0;
      }
  }
  }
  &__tabs {
    display: block;
    width: 100%;
    @include md{
      display: flex;
     }
    & .tab {
      z-index: 2;
      display: block;
      width: 100%;
      transition: transform 0.5s cubic-bezier(0.075, 1, 0.165, 1);
      height: 17rem;
      max-height: 80vh;
      @include md{
         display: flex;
         height: 66.6rem;
         justify-content: flex-end;
        }
        @include lg{
         height: 70.6rem;
        }
        @include xl{
          height: 86.6rem;
         }
      &.unactive{
        width: 100%;
        @include md{
         width: 90px;
        }
     }
      // TAB 01
      &.tab-01{
         transform: translate(0,0);
      }
      &.active.tab-01{
        transform: translate(0,-66%);
        @include md{
         transform: translate(-91%,0);
        }
        @include lg{
         transform: translate(-120%,0);
        }
     }
     &.unactive.tab-01{
        transform: translate(0,190%);
        @include md{
         transform: translate(547%,0)
        }
        @include lg{
         transform: translate(567%,0);
         z-index: 5;
        }
     }
     &.unactive.tab-01.active-light-blue{
      transform: translate(0,170%);
      @include xs{
        transform: translate(0,175%);
      }
      @include sm{
        transform: translate(0,191%);
      }
      @include md{
        transform: translate(828%,0);
      @include lg{
        transform: translate(780%,0);
      }
    }

   }
     &.unactive.tab-01.active-blue{
      transform: translate(0, 171%);
      @include xs{
        transform: translate(0, 177%);
      }
      @include sm{
        transform: translate(0, 188%);
      }
      @include md{
        transform: translate(828%, 0);
      }
      @include lg{
      transform: translate(812%,0);
       z-index:11;
      }
   }
      // TAB 02
      &.tab-02{
        transform: translate(0, -8.8rem);
        @include md{
         transform: translate(-25rem, 0);
        }
     }
     &.active.tab-02.active-light-blue{
        transform: translate(0,-166%);
        @include md{
         transform: translate(-115%,0);
        }
        @include lg{
         transform: translate(-142%,0);
        }
     }
     &.unactive.tab-02{
        transform: translate(0,90%);
        @include md{
         transform: translate(80%,0);
        }
        @include lg{
         transform: translate(135%,0);
        }
     }
     &.unactive.tab-02.active-red{
      transform: translate(0,75%);
      @include xs{
        transform: translate(0,75%);
      }
      @include sm{
        transform: translate(0,92%);
      }
      @include md{
        transform: translate(400%,0);
      }
      @include lg{
        transform: translate(335%,0);
      }
   }
     
     &.unactive.tab-02.active-blue{
         transform: translate(0, 93%);
         @include xs{
          transform: translate(0, 95%);
         }
         @include sm{
          transform: translate(0, 111%);
         }
        @include md{
         transform: translate(766%,0);
         z-index: 10;
        }
        @include lg{
         transform: translate(680%,0);
         z-index: 10;
        }
     }
     // TAB 03
     &.tab-03{
        transform: translate(0, -17rem);
        @include md{
         transform: translate(-50rem,0);
        }
     }
     &.unactive.tab-03{
        transform: translate(-0,13%);
        @include md{
         transform: translate(20%,0);
         z-index: 9;
        }
        @include lg{
         transform: translate(70%,0);
         z-index: 9;
        }
     }
     &.unactive.tab-03.active-light-blue{
      transform: translate(0,-11%);
      @include xs{
        transform: translate(0,-5%);
      }
      @include sm{
        transform: translate(0,13%);
      }
      @include md{
        transform: translate(340%,0);
      }
      @include lg{
        transform: translate(267%,0);
      }
   }
   &.unactive.tab-03.active-red{
    transform: translate(0,-2%);
    @include xs{
      transform: translate(0,0);
    }
    @include sm{
      transform: translate(0,13%);
    }
    @include md{
      transform: translate(340%,0);
    }
    @include lg{
      transform: translate(267%, 0);
    }
 }
     &.active.tab-03{
        transform: translate(0,-266%);
        @include md{
         transform: translate(-139%,0);
        }
        @include lg{
         transform: translate(-164%,0);
        }
     }
    }
  }
}


