.subtext-component{
    font-weight: bold;
    z-index: 1;
    & .titulo{ 
        margin-bottom: -4.5rem;
        & > span {
            display: inline-block;
            height: 8rem;
            color: $color-grey-light-1;
            &:nth-child(even){
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $color-grey-light-4;
            }
        }
        & .subtext-even, & .subtext-odd{
            font-size: 4rem;
            @include md{
                font-size: 7rem;
            }
        }
    }
    & .subtitulo{
        color: $color-grey-light-2;
        font-size: 2rem;
        line-height: 4.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        @include md{
            font-size: 3rem;
            line-height: 4.5rem;
            margin-top: 5rem;
        }
    }
}

