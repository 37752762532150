.work{
    display: flex;
    overflow: hidden;
    padding:  10rem 0;
    @include xs{
        padding: 18vh 0 10rem 0;
    }
    flex-direction: column;
    @include md{
        justify-content: space-between;
        flex-direction: row;
    }
    @include lg{
        padding: 17vh 0;
    }
    &__text{
        transition: all 0.8s ease-in-out;
        &.unactive{
            opacity: 0;
            transform: translate(5rem, 0);
        }
        &.active{
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}
