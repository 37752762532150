.menu{
    font-size: 10em;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 33vh;
    z-index: 10;
    font-size: 1.8rem;
    & a{
        cursor: pointer;
        height: 5.13rem;
        position: relative;
        display: flex;
        align-items: center;
        transition: all .3s ease-in-out;
        color: #444;
        margin-bottom: 1rem;
        &:hover{
            color: $color-primary;
        }
    }
    &__icon{
        margin: 0 0 1rem 0;
        padding: 0;
        height: 4.8rem;

    }
    &__text{
        color: inherit;
        text-transform: uppercase;
        font-size: 1.7rem;
    }
    & svg{
        font-size: 10em;
        width: 5.13rem;
        margin: auto 1rem 0 0;
        & path {
            fill: #444;
            transition: fill .3s ease-in-out;
        }
    }
}

.menu a:hover > svg path {
    fill: $color-primary;
}

.menu a.active{
    color:$color-primary;
    & > svg path{
        fill: $color-primary;
    }
}