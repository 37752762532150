.about{
    padding: 10rem 0;
    @include xs{
        padding: 25vh 0 10rem 0;
    }
    @include lg{
        padding: 17vh 0;
    }
    & .paragraph{
        margin-bottom:2rem;
        max-width: 66%;
        font-size: 1.2rem;
        @include xs{
            font-size: 1.8rem;
        }
    }
}

.mobile-selector{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    @include xs{
        display: none;
    }
    &__icon svg{
        width: 35px;
        height: auto;
        fill: $color-grey-dark-3;
    }
    & h2{
        font-size: 2rem;
        color: $color-grey-dark-3;
        text-transform: uppercase;
        margin-left: 1rem;
    }
}