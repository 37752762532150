.mobile-menu-container{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 9;
    @include xs{
        display: none;
    }
    &.active{
        opacity: 1;
        visibility: visible;
    }
    &.unactive{
        opacity: 0;
        visibility: hidden;
    }
}

.mobile-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
    height: 100%;
    flex-direction: column;
    & a span{
        font-size: 2rem;
        cursor: pointer;
    }
}