.container {
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: $shadow-dark;
    position: relative;
    min-height: 50rem;
    @include xl{
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;
    }
}

.content {
    align-items: center;
    height: 100vh;
    width: 100%;
    max-width: 1200px;
    display: grid;
    padding: 0 2rem 0;
    grid-template-columns: 100% auto;
    @include xs{
        padding: 0 2rem 0;
        grid-template-columns: 150px auto;
    }
    @include lg{
        padding:2rem 0;
    }

    &__menu{
        width: 30%;
        display: none;
        @include xs{
        display: block;
                }
    }

    &__content{
        width: 100%;
        position: relative;
        padding: 0 1rem;
        max-width: 1045px;
        min-width: 100%;
        @include xs{
            min-width: 718px;
            padding: 0 2rem 0 10%;
        }
        @include sm{
            padding: 0 0 0 10%;
        }
    }
}

section {
    padding: 10rem 0;
    margin: 0 auto;
    height: auto;
    @include xs {
        padding:0;
    }
  }