.work-subtext-component{
    font-size: 5rem;
    font-weight: bold;
    z-index: 5;
    & .titulo{ 
        font-size: 3.5rem;
        & > span {
            display: inline-block;
            height: 8rem;
            color: white;
            &:nth-child(even){
                color: transparent;
                -webkit-text-stroke-width: 0.5px;
                -webkit-text-stroke-color: #fff;
                @include lg{
                    -webkit-text-stroke-width: 0.5px;
                }
            }
        }
    }
}

