.portada {
  position: relative;
  width: 100%;
  text-align: left;
  display: block;
  padding: 10rem 0 20rem 0;
  @include xs {
    padding: 25vh 0 20rem 0;
    display: grid;
    grid-template-columns: 46% auto;
    grid-template-rows: auto;
  }
  @include md {
    padding: 17vh 0;
  }
  &__left {
    z-index: 4;
    transform: rotate(0);
    left: 0;
    position: relative;
    @include xs {
      transform: rotate(90deg);
      left: 0;
      position: relative;
      z-index: 0;
    }
    @include sm {
      transform: rotate(90deg);
    }
    @include md {
      transform: rotate(0);
      left: 0;
      position: relative;
    }
  }
  &__right {
    display: flex;
    z-index: 1;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    @include xs {
      left: -10rem;
      position: relative;
    }
    @include sm {
      justify-content: flex-end;
      position: relative;
      left: -20rem;
      z-index: 5;
    }
    @include md {
      left: 0;
      top: 1rem;
    }
  }
  &__phone {
    max-width: 130px;
    @include xs {
      max-width: 130px;
    }
    @include md {
      max-width: 200px;
    }
    &--01 {
      position: relative;
      left: 0;
      top: 4rem;
      @include xs {
        left: 12rem;
      }
      @include sm {
        left: -2rem;
      }
    }
    &--02 {
      position: relative;
      left: -2rem;
      top: 0;
      @include xs {
        left: 10rem;
      }
      @include sm {
        left: -4rem;
        top: 0;
      }
    }
  }
}

.copyright {
  text-align: center;
  width: 100%;
  position: relative;
  top: -12rem;
  &__icon svg{
      transform: rotate(-90deg);
      width: 20px;
      height: 20px;
  }
}
