.header {
    font-size: 1.4rem;
    height: 7rem;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    padding: 0 1rem;
    @include xs{
        flex-wrap: wrap;
        align-content: space-around;
        height: 11rem;
        padding: 0
    }
    &--container{
        max-width: 1200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 2rem;
    }
    &__logo{
        width: auto;
        height: 7em;
        cursor: pointer;
    }
    &__cta {
        width: auto;
        height: 12em;   
        cursor: pointer;
        opacity: 1;
        display: none;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        @include xs{
            display: block;
        }   
        &:hover {
            opacity: 0.5;
        }
        &:active {
            opacity: 0.2;
        }
    }
    &__hamburguer {
        cursor: pointer;
        display: block;
        @include xs{
            display: none;
        }
    }
}
